import { useState, ChangeEvent, FormEvent, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { submitFeedback } from "../../api/feedbackAPI";
import "./FeedbackModal.css";

const FeedbackModal = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    content: "",
  });
  const pastedContentLengthRef = useRef(0);

  const dataLength = () => {
    let len = 0;
    for (let char of formData.content) {
      len++;
    }
    return len;
  };

  const totalLength = dataLength();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const isBackspace =
      e.nativeEvent instanceof InputEvent &&
      e.nativeEvent.inputType === "deleteContentBackward";
    const { value } = e.target;
    const pastedContent = value.slice(
      totalLength - pastedContentLengthRef.current
    );
    const newTotalLength = totalLength - pastedContent.length;

    if ((newTotalLength <= 200 && value.length <= 200) || isBackspace) {
      setFormData((prevData) => ({
        ...prevData,
        content: value,
      }));
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedContent = e.clipboardData.getData("text/plain");
    const remainingSpace = 200 - totalLength;
    console.log(remainingSpace, "remainingSpace");
    if (pastedContent.length > remainingSpace) {
      const truncatedContent = pastedContent.slice(0, remainingSpace);
      setFormData((prevData) => ({
        ...prevData,
        content: prevData.content + truncatedContent,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        content: prevData.content + pastedContent,
      }));
    }

    e.preventDefault();
  };

  const handleEmojiClick = (emojiObject: any) => {
    const emoji = emojiObject.native;
    if (totalLength < 200) {
      setFormData((prevData) => ({
        ...prevData,
        content: prevData.content + emoji,
      }));
    }

    setShowEmojiPicker(false);
  };

  const handleEmojiClose = (e: React.MouseEvent<HTMLDivElement>) => {
    const emojiContainer = document.querySelector(".emoji-container");
    if (emojiContainer && !emojiContainer.contains(e.target as Node)) {
      setShowEmojiPicker(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await submitFeedback(formData);
      if (response) {
        showToast();
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
    setFormData({
      firstName: "",
      lastName: "",
      content: "",
    });
    setLoading(false);
  };

  const showToast = () => {
    toast.success(t("thanks for feedback"), {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
      style: { color: "#A069E1FF" },
    });
  };

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
        className="feedback-title"
      >
        {t("give feedback")}
      </div>
      <a href="/feedbacks" className="show-feedbacks-link">
        {t("all feedbacks")}
      </a>
      {isOpen && (
        <div className="feedbackPopupContainer" onClick={handleEmojiClose}>
          <div className="feedbackPopup">
            <div className="feedbackClose" onClick={handleClose}>
              x
            </div>
            <h3 className="feedbackMassage">{t("feedback massage")}</h3>
            {loading ? (
              <span className="feedbackLoader"></span>
            ) : (
              <form onSubmit={handleSubmit} className="feedbackForm">
                <div className="feedbackFullname">
                  <div className="feedbackName">
                    <div className="feedbackNameLabel">{t("name")}</div>
                    <input
                      className="feedbackInput"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="feedbackName">
                    <div className="feedbackNameLabel">{t("last name")}</div>
                    <input
                      className="feedbackInput"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="feedbackName">
                  <div className="feedbackNameLabel">{t("feedback")}</div>
                  <div className="feedbackTextAreaContainer">
                    <textarea
                      name="content"
                      value={formData.content}
                      onChange={handleContentChange}
                      onPaste={handlePaste}
                      required
                      className="feedbackInput feedbackContent"
                    />
                    <button
                      type="button"
                      className="emojiPickerButton"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      😊
                    </button>
                    <div className="characterCount">
                      {totalLength}/{200} characters
                    </div>
                    {showEmojiPicker && (
                      <div className="emoji-container">
                        <Picker
                          style={{ innerHeight: 200 }}
                          data={data}
                          previewPosition="none"
                          onEmojiSelect={handleEmojiClick}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className="feedbackSubmit"
                  disabled={
                    !formData.content.length ||
                    !formData.firstName.length ||
                    !formData.lastName.length
                  }
                  type="submit"
                >
                  {t("submit")} 🚀
                </button>
              </form>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default FeedbackModal;
