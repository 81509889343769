import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StarRate from "../StarRate";
import BMIChart from "../Charts/BMIChart";
import FormulaChart from "../Charts/FormulaChart";
import AnalyticalChart, { ChartDataType } from "../Charts/AnalyticalChart";
import { fetchAnalytics } from "../../api/userAPI";
import { useRequestContext } from "../../context/RequestCount";
import GoBack from "../GoBack";
import FeedbackModal from "../FeedbackModal";
import "./result.css";

const Result: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const data = location.state?.data;
  const [weightChart, setWeightChart] = useState<ChartDataType[]>([]);
  const [ageChart, setAgeChart] = useState<ChartDataType[]>([]);
  const [genderChart, setGenderChart] = useState<ChartDataType[]>([]);

  const navigate = useNavigate();

  const { requestsCount } = useRequestContext();

  const weightTranslation = {
    overweight: t("overweight"),
    underweight: t("underweight"),
    normal: t("normal"),
  };

  const genderTranslation = {
    Male: t("male"),
    Female: t("female"),
  };

  useEffect(() => {
    if (!data) {
      navigate("/");
    } else {
      updateRequestsCount();
    }
  }, [data, navigate, t]);

  const formatAgeRange = (ageRange: string) => {
    let [lower, upper] = ageRange.split("-");
    if (lower === "0") return t("up to years", { upper });

    if (lower.includes("+")) {
      lower = lower.replace(/\+/g, "");
      return t("over years old", { lower });
    }
    return t("to years old", { lower, upper });
  };

  const updateRequestsCount = async () => {
    try {
      const response = await fetchAnalytics();
      if (response) {
        const { byWeightStatus, byAge, byGender } = await response.json();

        const formattedWeightData = byWeightStatus.map(
          (item: {
            weightStatus: "overweight" | "underweight" | "normal";
            count: number;
          }) => [weightTranslation[item.weightStatus], item.count]
        );
        setWeightChart([["Weight Status", "Count"], ...formattedWeightData]);

        byAge.sort(
          (
            a: { ageRange: string; count: number },
            b: { ageRange: string; count: number }
          ) => a.ageRange.localeCompare(b.ageRange)
        );
        const formattedAgeData = byAge.map(
          (item: { ageRange: string; count: number }) => [
            formatAgeRange(item.ageRange),
            Number(item.count),
          ]
        );
        setAgeChart([["Age", "Count"], ...formattedAgeData]);

        setGenderChart([
          ["Gender", "Count"],
          ...byGender.map(
            (item: { gender: "Male" | "Female"; count: number }) => [
              genderTranslation[item.gender],
              item.count,
            ]
          ),
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (
    !data ||
    weightChart.length === 0 ||
    ageChart.length === 0 ||
    genderChart.length === 0
  ) {
    return null;
  }

  const {
    BMI,
    actualWeight,
    devineFormula,
    hamwiForumla,
    millerFormula,
    robinsonFormula,
  } = data;

  const category = {
    byAge: t("by age"),
    byWeight: t("by weight"),
    byGender: t("by gender"),
  };

  return (
    <div className="result-container">
      <>
        <GoBack />
        <div className="chart-container">
          <BMIChart
            min={BMI.minWeight}
            max={BMI.maxWeight}
            actualWeight={actualWeight}
          />
          <FormulaChart
            names={["Devine", "Hamwi", "Miller", "Robinson"]}
            weights={[
              devineFormula,
              hamwiForumla,
              millerFormula,
              robinsonFormula,
            ]}
          />
        </div>
        <h2 className="analytics-heading">
          {t("requestCountMessage", { requestsCount })}
        </h2>
        <div className="analytics-container">
          <AnalyticalChart
            chartData={weightChart}
            category={category.byWeight}
            colors={["#A069E1", "#38DE61", "#EB4F8E"]}
          />
          <AnalyticalChart
            chartData={ageChart}
            category={category.byAge}
            colors={["#EB473B", "#38DE61", "#FBBE0D", "#69C7EB"]}
          />
          <AnalyticalChart
            chartData={genderChart}
            category={category.byGender}
            colors={["#69C7EB", "#EB4F8E"]}
          />
        </div>
        <FeedbackModal />
        <StarRate />
      </>
    </div>
  );
};

export default Result;
