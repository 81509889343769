import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../LanguageSwitcher";
import heart from "../../assets/heart.png";
import "./Header.css";

type HeaderPropType = {
  count: number;
};

const Header: React.FC<HeaderPropType> = ({ count }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="marquee">
        <div className="marquee__inner">
          <p className="marquee__line">
            {t("idealWeightMessage", { count })}
            <img src={heart} alt="Icon" className="heart-icon" />
          </p>
          <p className="marquee__line">
            {t("idealWeightMessage", { count })}
            <img src={heart} alt="Icon" className="heart-icon" />
          </p>
          <p className="marquee__line">
            {t("idealWeightMessage", { count })}
            <img src={heart} alt="Icon" className="heart-icon" />
          </p>
        </div>
      </div>
      <LanguageSwitcher />
    </>
  );
};

export default Header;
