const base_url = process.env.REACT_APP_BASE_URL;

interface FeedbackToSubmit {
  firstName: string;
  lastName: string;
  content: string;
}

export interface Feedback {
  id: number;
  fullName: string;
  content: string;
  createdAt: string;
}

export interface FeedbackResponse {
  data: Feedback[];
  meta: {
    pageCount: number;
  };
}

const submitFeedback = async (feedback: FeedbackToSubmit) => {
  try {
    const response = await fetch(`${base_url}/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedback),
    });

    if (!response.ok) {
      throw new Error(`Error submiting feedback: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error submiting feedback:", error);
  }
};

const getFeedbacks = async (page: number, take: number) => {
  try {
    const response = await fetch(
      `${base_url}/feedback?order=ASC&page=${page}&take=${take}`
    );

    if (!response.ok) {
      throw new Error(`Error fetching feedbacks: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error fetching feedbacks:", error);
  }
};

export { submitFeedback, getFeedbacks };
