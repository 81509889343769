const base_url = process.env.REACT_APP_BASE_URL;

const fetchAppRatings = async () => {
  try {
    const response = await fetch(`${base_url}/app-ratings`);
    if (!response.ok) {
      throw new Error(`Error fetching app ratings: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error fetching average rating:", error);
    throw error;
  }
};

const submitAppRating = async (rating: number) => {
  try {
    const response = await fetch(`${base_url}/app-ratings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ rating }),
    });

    if (!response.ok) {
      throw new Error(`Error submiting app ratings: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error submiting app ratings:", error);
  }
};

export { fetchAppRatings, submitAppRating };
