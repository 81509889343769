const base_url = process.env.REACT_APP_BASE_URL;

const fetchUserProfiles = async () => {
  try {
    const response = await fetch(`${base_url}/user-profile`);
    if (!response.ok) {
      throw new Error(`Error fetching user profile: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Fetch User Profile Error:", error);
    throw error;
  }
};

interface UserData {
  age: number | "";
  height: number | "";
  gender: string;
  weight: number | "";
}

const calculateUserWeight = async (userData: UserData) => {
  try {
    const response = await fetch(`${base_url}/user-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    if (!response.ok) {
      throw new Error(`Error updating user profile: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

const fetchAnalytics = async () => {
  try {
    const response = await fetch(`${base_url}/user-profile/analytics`);
    if (!response.ok) {
      throw new Error(`Error fetching user profile: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Fetch User Profile Error:", error);
    throw error;
  }
};

export { fetchUserProfiles, calculateUserWeight, fetchAnalytics };
