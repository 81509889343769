import React, { ReactNode } from "react";
import Header from "../Header";
import { useRequestContext } from "../../context/RequestCount";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { requestsCount } = useRequestContext();

  return (
    <>
      {requestsCount !== null && (
        <>
          <Header count={requestsCount} />
          {children}
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default Layout;
