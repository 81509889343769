import React, { useState, useEffect } from "react";
import { Rate } from "antd";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAppRatingsData } from "../InputForm/input-form.interfaces";
import { fetchAppRatings, submitAppRating } from "../../api/ratingsAPI";
import "./starRate.css";

const StarRate: React.FC = () => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [showContent, setShowContent] = useState(true);
  const [averageRating, setAverageRating] = useState<number | null>(null);
  const [averageRatingCount, setAverageRatingCount] = useState<number | null>(
    null
  );

  const fetchAverageRating = async () => {
    try {
      const response = await fetchAppRatings();
      if (response) {
        const data: IAppRatingsData = await response.json();

        let allRatings = 0;

        for (let i = 0; i < data.ratings.length; i++) {
          allRatings += data.ratings[i].rating;
        }

        setAverageRating(
          allRatings ? Number((allRatings / data.count).toFixed(1)) : 0
        );
        setAverageRatingCount(data.count);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAverageRating();
  }, []);

  const handleRateChange = async (value: number) => {
    setRating(value);
  };

  const handleSubmit = async () => {
    try {
      const response = await submitAppRating(rating);
      if (response) {
        showToast();
        setTimeout(() => {
          setShowContent(false);
        }, 1000);
      }
      await fetchAverageRating();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const showToast = () => {
    toast.success(t("thanks for voting"), {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      icon: false,
      style: { color: "#A069E1FF" },
    });
  };

  return (
    <>
      {showContent ? (
        <>
          <div className="stars-container">
            <div style={{ flex: 1 }}></div>
            <Rate
              className="stars"
              value={rating}
              onChange={handleRateChange}
            />
            <div
              className="average-rating"
              style={{
                flex: 1,
                textAlign: "start",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span className="rating-number">{averageRating}</span>
              <span className="count-number">
                ({averageRatingCount} {t("votes")})
              </span>
            </div>
          </div>
          <button
            disabled={!rating}
            className="submit-button"
            type="button"
            onClick={handleSubmit}
          >
            {t("confirm")}
          </button>
        </>
      ) : (
        <button
          className="submit-button back"
          onClick={() => {
            window.history.back();
          }}
        >
          {t("back")}
        </button>
      )}
      <ToastContainer />
    </>
  );
};

export default StarRate;
