import "./CalculateButton.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { IIsDisabledProps } from "./calculate-button.types";

const CalculateButton: React.FC<IIsDisabledProps> = ({ isDisabled }) => {
  const { t } = useTranslation();

  return (
    <div>
      <button disabled={isDisabled} type="submit" className="calculate-button">
        {t("calculate")}
      </button>
    </div>
  );
};

export default CalculateButton;
