import React from "react";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";

import "./chart.css";

Chart.register(ArcElement);

interface BMIChartProps {
  min: number;
  max: number;
  actualWeight: number;
}

const BMIChart: React.FC<BMIChartProps> = ({ min, max, actualWeight }) => {
  const { t } = useTranslation();

  const data = {
    datasets: [
      {
        data: [10, 10, 10],
        backgroundColor: ["#A069E1FF", "#38DE61FF", "#B791DDFF"],
        display: true,
        borderColor: "#D1D6DC",
      },
    ],
    labels: [t("underweight"), t("normal"), t("overweight")],
  };

  const findDegree = () => {
    let rotationAngle = 0;
    const diff = max - min;

    const maxEdge = max + diff;
    const minEdge = min - diff;

    if (actualWeight >= maxEdge) {
      rotationAngle = 90;
      return rotationAngle;
    }
    if (actualWeight <= minEdge) {
      rotationAngle = -90;
      return rotationAngle;
    }

    const forEachKG = 60 / (max - min);
    return (actualWeight - min) * forEachKG - 30;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <h2
        style={{
          color: "#21004b",
          position: "absolute",
          width: "100%",
          top: "-10%",
        }}
      >
        {t("result")}
      </h2>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-around",
            width: "120%",
            padding: "0 20px",
            boxSizing: "border-box",
            top: "28%",
            left: "51%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span
            style={{
              transform: "rotate(-30deg)",
            }}
          >
            {min} {t("kg")}
          </span>
          <span
            style={{
              transform: "rotate(30deg)",
              paddingLeft: 5,
            }}
          >
            {max} {t("kg")}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            width: "100%",
          }}
        >
          <FontAwesomeIcon
            icon={faArrowUpLong}
            style={{
              width: 200,
              height: 75,
              display: "flex",
              transform: `rotate(${findDegree()}deg)`,
              transformOrigin: "bottom center",
              color: "#21004b",
            }}
          />
          <span
            style={{
              marginTop: 5,
            }}
          >
            {actualWeight} {t("kg")}
          </span>
        </div>
        <Doughnut
          data={data}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            rotation: -90,
            circumference: 180,
            cutout: "60%",
            maintainAspectRatio: true,
            responsive: true,
          }}
          style={{
            width: 400,
          }}
          className="bmi-chart"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 17,
            position: "absolute",
            width: "100%",
            bottom: "5%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {data.labels.map((label, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  backgroundColor: data.datasets[0].backgroundColor[index],
                  padding: "8px",
                  borderRadius: "10px",
                }}
              ></span>
              <span style={{ marginLeft: "5px" }}>{label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BMIChart;
