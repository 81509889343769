import React from "react";
import { Chart } from "react-google-charts";

export type ChartDataType = [string, string | number];

interface AnalyticalChartProps {
  chartData: ChartDataType[];
  category: string;
  colors: string[];
}

const AnalyticalChart: React.FC<AnalyticalChartProps> = ({
  chartData,
  category,
  colors,
}) => {
  const options = {
    colors,
    backgroundColor: "transparent",
    sliceVisibilityThreshold: 0,
    legend: {
      textStyle: {
        color: "#004242",
      },
    },
    chartArea: {
      left: "0%",
      top: "10%",
      width: "100%",
      height: "100%",
    },
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div className="analytic-chart-title">{category}</div>
      <Chart
        chartType="PieChart"
        data={chartData}
        options={options}
        className="analytic-chart"
      />
    </div>
  );
};

export default AnalyticalChart;
