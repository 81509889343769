import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./App.css";
import InputForm from "./components/InputForm";
import Result from "./components/Result";
import Feedbacks from "./components/Feedbacks";
import Layout from "./components/Layout";
import { RequestContextProvider } from "./context/RequestCount";

function App() {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <RequestContextProvider>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<InputForm />} />
                <Route path="/result" element={<Result />} />
                <Route path="/feedbacks" element={<Feedbacks />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </RequestContextProvider>
      </I18nextProvider>
    </>
  );
}

export default App;
