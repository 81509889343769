import React, { useEffect, useRef } from "react";
import Chart, { ChartConfiguration } from "chart.js/auto";
import { useTranslation } from "react-i18next";

interface FormulaChartProps {
  names: string[];
  weights: number[];
}

const FormulaChart: React.FC<FormulaChartProps> = ({ names, weights }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    let chartInstance: Chart<"bar"> | null = null;

    const resizeChart = () => {
      if (chartInstance) {
        chartInstance.destroy();
      }

      if (chartRef && chartRef.current) {
        const ctx = chartRef.current.getContext("2d");
        if (ctx) {
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(1, "#A069E1FF");

          const config: ChartConfiguration<"bar"> = {
            type: "bar",
            data: {
              labels: names,
              datasets: [
                {
                  label: t("weight kg"),
                  data: weights,
                  backgroundColor: gradient,
                  borderColor: "rgba(54, 162, 235, 1)",
                  borderWidth: 1,
                  barPercentage: 0.6,
                  categoryPercentage: 0.8,
                },
              ],
            },
            options: {
              indexAxis: "x",
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: t("weight kg"),
                    font: {
                      size: 16,
                    },
                  },
                },
              },
            },
          };

          chartInstance = new Chart(ctx, config);
        }
      }
    };

    resizeChart();

    const handleResize = () => {
      resizeChart();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [names, weights]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        maxWidth: "700px",
        minWidth: "200px",
      }}
    >
      <h2
        style={{
          color: "#21004b",
        }}
      >
        {t("formula results")}
      </h2>
      <canvas ref={chartRef} />
    </div>
  );
};

export default FormulaChart;
