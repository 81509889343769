import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUserProfiles } from "../api/userAPI";

interface RequestContextProps {
  children: React.ReactNode;
}

interface RequestContextValue {
  requestsCount: number | null;
  updateRequestsCount: () => Promise<void>;
}

const RequestContext = createContext<RequestContextValue>({
  requestsCount: null,
  updateRequestsCount: async () => {},
});

export const useRequestContext = () => useContext(RequestContext);

export const RequestContextProvider: React.FC<RequestContextProps> = ({
  children,
}) => {
  const [requestsCount, setRequestsCount] = useState<number | null>(null);

  const updateRequestsCount = async () => {
    try {
      const response = await fetchUserProfiles();
      if (response) {
        const count = await response.json();
        const formattedCount = count
          ? count > 1000
            ? (count / 1000).toFixed(3).replace(".", ",")
            : count.toString()
          : "0";
        setRequestsCount(formattedCount);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    updateRequestsCount();
  }, []);

  const contextValue: RequestContextValue = {
    requestsCount,
    updateRequestsCount,
  };

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
};
