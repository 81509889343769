import React from "react";
import { useTranslation } from "react-i18next";

import "./languageSwitcher.css";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="languageButtonContainer">
      <button
        onClick={() => changeLanguage("am")}
        className={`languageButton ${i18n.language === "am" ? "active" : ""}`}
      >
        Հայ
      </button>
      <button
        onClick={() => changeLanguage("en")}
        className={`languageButton ${i18n.language === "en" ? "active" : ""}`}
      >
        Eng
      </button>
    </div>
  );
};

export default LanguageSwitcher;
