import React, { useRef, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Feedback,
  FeedbackResponse,
  getFeedbacks,
} from "../../api/feedbackAPI";
import arrow from "../../assets/arrow.png";
import "./FeedbackCarousel.css";

interface AvatarProps {
  name: string;
  surname: string;
}

const Avatar: React.FC<AvatarProps> = ({ name, surname }) => {
  const colors = [
    "#bd5c3c",
    "#57372d",
    "#FF33FF",
    "#bebe3f",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#49d849",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#e99494",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
  ];
  const [randomColor] = useState(
    colors[Math.floor(Math.random() * colors.length)]
  );

  return (
    <div className="slide-img" style={{ backgroundColor: randomColor }}>
      {name.charAt(0)}
      {surname.charAt(0)}
    </div>
  );
};

interface CardProps {
  feedback: Feedback;
}

const CardComponent: React.FC<CardProps> = ({ feedback }) => (
  <div key={feedback.id} className="slide">
    <Avatar
      name={feedback.fullName.split(" ")[0]}
      surname={feedback.fullName.split(" ")[1]}
    />
    <div className="slide-feedback-container">
      <p className="slide-feedback-content">{feedback.content}</p>
      <h3 className="slide-feedback-fullname">{feedback.fullName}</h3>
      <div className="slide-feedback-date">
        {feedback.createdAt.split("T")[0].replaceAll("-", "/")}
      </div>
    </div>
  </div>
);

const FeedbackSlider: React.FC = memo(() => {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await getFeedbacks(1, 10);
        const responseData = response as FeedbackResponse;
        if (responseData) {
          setFeedbacks(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFeedbacks();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <h2 className="slider-title">{t("user feedbacks")}</h2>
      <div className="slide-container">
        <Slider ref={sliderRef} {...settings}>
          {feedbacks.map((feedback) => (
            <CardComponent key={feedback.id} feedback={feedback} />
          ))}
        </Slider>
        <div className="arrow-button-container">
          <img
            className="arrow-button prev"
            src={arrow}
            alt="previous"
            onClick={goToPrev}
          />
          <img
            className="arrow-button"
            src={arrow}
            alt="next"
            onClick={goToNext}
          />
        </div>
      </div>
    </>
  );
});

export default FeedbackSlider;
