import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import CalculateButton from "../CalculateButton";
import {
  MAX_AGE_VALUE,
  MAX_HEIGHT_VALUE,
  MIN_AGE_VALUE,
  MIN_HEIGHT_VALUE,
  MIN_WEIGHT_VALUE,
  MAX_WEIGHT_VALUE,
} from "./constants";
import { Gender } from "./input-form.types";
import FeedbackSlider from "../FeedbackCarousel";
import { useRequestContext } from "../../context/RequestCount";
import { calculateUserWeight } from "../../api/userAPI";
import Loader from "../Loader";
import Male from "../../assets/male.png";
import Female from "../../assets/female.png";
import "./InputForm.css";

const InputForm = () => {
  const { t } = useTranslation();
  const [height, setHeight] = useState<number | "">("");
  const [weight, setWeight] = useState<number | "">("");
  const [age, setAge] = useState<number | "">("");
  const [gender, setGender] = useState<Gender>(Gender.MALE);
  const [ageError, setAgeError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();

  const { updateRequestsCount } = useRequestContext();

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const heightValue = Number(e.target.value);

    if (
      isNaN(heightValue) ||
      heightValue < 0 ||
      heightValue !== Math.floor(heightValue)
    ) {
      setHeightError(t("integer error"));
    } else if (heightValue < MIN_HEIGHT_VALUE) {
      setHeightError(t("min value") + `${MIN_HEIGHT_VALUE}`);
    } else if (heightValue > MAX_HEIGHT_VALUE) {
      setHeightError(t("max value") + `${MAX_HEIGHT_VALUE}`);
    } else {
      setHeightError("");
    }

    setHeight(heightValue || "");
  };

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const weightValue = Number(e.target.value);

    if (
      isNaN(weightValue) ||
      weightValue < 0 ||
      weightValue !== Math.floor(weightValue)
    ) {
      setWeightError(t("integer error"));
    } else if (weightValue < MIN_WEIGHT_VALUE) {
      setWeightError(t("min value") + `${MIN_WEIGHT_VALUE}`);
    } else if (weightValue > MAX_WEIGHT_VALUE) {
      setWeightError(t("max value") + `${MAX_WEIGHT_VALUE}`);
    } else {
      setWeightError("");
    }

    setWeight(weightValue || "");
  };

  const handleAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const ageValue = Number(e.target.value);

    if (isNaN(ageValue) || ageValue < 0 || ageValue !== Math.floor(ageValue)) {
      setAgeError(t("integer error"));
    } else if (ageValue < MIN_AGE_VALUE) {
      setAgeError(t("min value") + `${MIN_AGE_VALUE}`);
    } else if (ageValue > MAX_AGE_VALUE) {
      setAgeError(t("max value") + `${MAX_AGE_VALUE}`);
    } else {
      setAgeError("");
    }

    setAge(ageValue || "");
  };

  const calculateIdealWeight = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!ageError && !heightError && !weightError) {
      try {
        const response = await calculateUserWeight({
          age,
          height,
          gender,
          weight,
        });
        if (response.ok) {
          const data = await response.json();
          data.actualWeight = weight;
          setShowLoading(true);
          setTimeout(() => {
            navigate(`result`, { state: { data } });
            updateRequestsCount();
          }, 4000);
        } else {
          console.error("Error:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <h1 className="description"></h1>
      <h1 className="title">{t("title")}</h1>
      <p className="description">{t("description")}</p>
      {showLoading ? (
        <Loader />
      ) : (
        <>
          <form className="form-container" onSubmit={calculateIdealWeight}>
            <div className="input-card-container">
              <div className="input-card bgImageGender">
                <button className="circle">1</button>
                <h2>{t("gender")}</h2>
                <div>
                  <hr className="horizontal-line" />
                </div>
                <div className="gender-images">
                  <div
                    className="gender-box"
                    onClick={() => setGender(Gender.MALE)}
                  >
                    <img className="icon-svg" src={Male} alt="Male" />
                    <button
                      className={`gender-button ${
                        gender === Gender.MALE ? "active" : ""
                      }`}
                    >
                      {t("male")}
                    </button>
                  </div>
                  <div
                    className="gender-box"
                    onClick={() => setGender(Gender.FEMALE)}
                  >
                    <img className="icon-svg" src={Female} alt="Female" />
                    <button
                      className={`gender-button ${
                        gender === Gender.FEMALE ? "active" : ""
                      }`}
                    >
                      {t("female")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="input-card">
                <button className="circle">2</button>
                <h2>{t("age")}</h2>
                <div>
                  <hr className="horizontal-line" />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    placeholder="21"
                    className="custom-input"
                    value={age}
                    onChange={handleAgeChange}
                  />
                  <div className="label">{t("years old")}</div>
                </div>

                {ageError && (
                  <div className="error-message">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      style={{
                        color: "#fff",
                      }}
                    />{" "}
                    {ageError}
                  </div>
                )}
              </div>

              <div className="input-card">
                <button className="circle">3</button>
                <h2>{t("height")}</h2>
                <div>
                  <hr className="horizontal-line" />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    placeholder="170"
                    className="custom-input cm"
                    onChange={handleHeightChange}
                    value={height}
                  />
                  <div className="label">{t("sm")}</div>
                </div>
                {heightError && (
                  <div className="error-message">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      style={{
                        color: "#fff",
                      }}
                    />{" "}
                    {heightError}
                  </div>
                )}
              </div>

              <div className="input-card">
                <button className="circle">4</button>
                <h2>{t("weight")}</h2>
                <div>
                  <hr className="horizontal-line" />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    placeholder="65"
                    className="custom-input cm"
                    onChange={handleWeightChange}
                    value={weight}
                  />
                  <div className="label">{t("kg")}</div>
                </div>
                {weightError && (
                  <div className="error-message">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      style={{
                        color: "#fff",
                      }}
                    />{" "}
                    {weightError}
                  </div>
                )}
              </div>
            </div>
            <CalculateButton
              isDisabled={
                !!(
                  ageError.length ||
                  heightError.length ||
                  weightError.length ||
                  !age ||
                  !height ||
                  !weight
                )
              }
            />
          </form>
          <FeedbackSlider />
          <div className="show-feedbacks-container">
            <button
              onClick={() => navigate("/feedbacks")}
              className="show-feedbacks"
            >
              {t("all feedbacks")}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default InputForm;
