import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

import "./GoBack.css";

const GoBack = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className="backwards-container">
      <div className="backwards" onClick={handleNavigate}>
        <FontAwesomeIcon icon={faBackward} style={{ color: "#a069e1ff" }} />
        <div>{t("back")}</div>
      </div>
    </div>
  );
};

export default GoBack;
