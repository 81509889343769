import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { Feedback, getFeedbacks } from "../../api/feedbackAPI";
import GoBack from "../GoBack";
import quotes from "../../assets/quotes.png";
import person from "../../assets/colored-person.png";

import "./Feedbacks.css";

const FeedbackPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageFromUrl = queryParams.get("page") || "1";
  const takeFromUrl = queryParams.get("take") || "5";
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [page, setPage] = useState(parseInt(pageFromUrl));
  const [take, setTake] = useState(parseInt(takeFromUrl));
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(parseInt(pageFromUrl));
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const indexOfFirstItem = page === 1 ? page : (page - 1) * take + 1;
  const indexOfLastItem =
    page === 1
      ? feedbacks.length
      : page == totalPages
      ? totalCount
      : page * take;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await getFeedbacks(page, take);
        if (response) {
          setFeedbacks(response.data);
          setHasPreviousPage(response.meta.hasPreviousPage);
          setHasNextPage(response.meta.hasNextPage);
          setTotalPages(response.meta.pageCount);
          setTotalCount(response.meta.totalCount);
        }
      } catch (error) {
        console.error("Error fetching feedbacks:", error);
      }
    };

    fetchFeedbacks();
  }, [page, take]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      setSelectedPage(newPage);
      navigate(`/feedbacks?page=${newPage}&take=${take}`);
    }
  };

  const handleTakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newTake = Number(event.target.value);
    setTake(newTake);
    setPage(1);
    navigate(`/feedbacks?page=1&take=${newTake}`);
  };

  const handlePageSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedPage > 0 && selectedPage <= totalPages) {
      setPage(selectedPage);
      navigate(`/feedbacks?page=${selectedPage}&take=${take}`);
    }
  };

  return (
    <>
      <GoBack />
      <div className="feedback-wrapper">
        <div className="feedback-container">
          {feedbacks.map((feedback) => (
            <div className="feedback-item-card" key={feedback.id}>
              <div className="quotes-container">
                <img src={quotes} alt="quotes" className="quotes" />
              </div>
              <div className="person-image-container">
                <img src={person} alt="person" className="person-icon" />
              </div>
              <div className="feedback-person-info">
                <p className="feedback-info-content">{feedback.content}</p>
                <div className="feedback-fullname-container">
                  <p className="feedback-info-fullname">{feedback.fullName}</p>
                  <div className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <FontAwesomeIcon
            className="forward-outer"
            icon={faForwardStep}
            style={{
              color: hasPreviousPage ? "#0079af" : "#ccc",
              cursor: hasPreviousPage ? "pointer" : "not-allowed",
              transform: "rotate(180deg)",
            }}
            onClick={() => handlePageChange(1)}
          />
          <button
            className="pagination-button"
            onClick={() => handlePageChange(page - 1)}
            disabled={!hasPreviousPage}
          >
            <FontAwesomeIcon
              className="forward-inner"
              icon={faCaretRight}
              style={{
                color: hasPreviousPage ? "#0079af" : "#ccc",
                transform: "rotate(180deg)",
              }}
            />
            {t("previous")}
          </button>
          <form onSubmit={handlePageSubmit} className="feedback-form">
            <input
              className="feedback-input"
              type="number"
              id="pageSelect"
              min={1}
              max={totalPages}
              value={selectedPage}
              onChange={(e) => setSelectedPage(parseInt(e.target.value, 10))}
            />
            <button className="feedback-button" type="submit">
              Go
            </button>
          </form>
          <button
            className="pagination-button"
            onClick={() => handlePageChange(page + 1)}
            disabled={!hasNextPage}
          >
            {t("next")}
            <FontAwesomeIcon
              className="forward-inner"
              icon={faCaretRight}
              style={{ color: hasNextPage ? "#0079af" : "#ccc" }}
            />
          </button>
          <FontAwesomeIcon
            icon={faForwardStep}
            className="forward-outer"
            style={{
              color: hasNextPage ? "#0079af" : "#ccc",
              cursor: hasNextPage ? "pointer" : "not-allowed",
            }}
            onClick={() => handlePageChange(totalPages)}
          />
          <div className="feedback-select-container">
            <select
              className="feedback-select"
              id="takeSelect"
              value={take}
              onChange={handleTakeChange}
            >
              <option value={6}>6</option>
              <option value={12}>12</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>

        <div className="feedback-details-container">
          <div>
            {t("feedback details", {
              indexOfFirstItem,
              indexOfLastItem,
              totalCount,
            })}
          </div>
          <div>
            {t("total pages")}։ {totalPages}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackPage;
